





























































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { apiGetCustomerList, apiShopLists } from "@/api/shop";
import { apiOtherResources } from "@/api/goods";
import {
    apiPhysicalVouchersCouponBackLists,
    apiOrderCheckCardNoData,
    apiOrderAdd,
    apiGetCategroy,
} from "@/api/card/physical_vouchers";
import { apiProductFileLists } from "@/api/productFile";
import LinkSelect from "@/components/link-select/index.vue";
@Component({
    components: { LinkSelect },
})
export default class voucherCardAdd extends Vue {
    steps: any = 1;
    currentName: string = "1";
    custom_list: any[] = [];
    productFileLists: any[] = [];
    shopList: any[] = [];
    form_extens: any = {
        sales_discount: "", //销售折扣
        order_amount: "", //订单金额
        basePrice: "", //订单金额
    };
    cardMallType: any = [
        {
            title: "按次兑换",
            list: [
                {
                    id: 1,
                    title: "电影次票券",
                    discrble:
                        "按次兑换电影票，可限制兑换城市，兑换影院，以及兑换方式（线上线下）",
                },
                {
                    id: 3,
                    title: "N选1兑换券",
                    discrble:
                        "可限定用户在指定几种商品里面选择一个商品进行兑换",
                },
            ],
        },
        {
            title: "储值兑换",
            list: [
                {
                    id: 5,
                    title: "全场通用储值券",
                    discrble: "可购买商城所有商品",
                },
                {
                    id: 2,
                    title: "指定品类储值券",
                    discrble: "可限定用户在限定的品类下进行商品的购买",
                },
                {
                    id: 4,
                    title: "指定商品储值券",
                    discrble: "可限定用户只能购买指定的几种商品",
                },
            ],
        },
    ];
    // 库存
    stockeData: any = [];
    stockQuery: any = {
        no1: "",
        no2: "",
    };
    //选中的库存
    currentRow: any = {};

    //号段
    cardNoData: any = [];
    cardNoDataIndex = new Date().getTime();

    // 添加商城表单数据
    form: any = {
        coupons_type: 1,
        customer_id: "",
        scope_of_application: 2,
        sid: "",
        validity_period: "",
        validity_period_start: "",
        validity_period_end: "",
        expiration_date: "",
        activation_method: 1,
        activation_time: "",
        is_electronic_signature: 1,
        benchmark_price: "",
        basePrice: "",
        discount_cap: "",
        sale_unit_price: "",
        sale_number: "",
        included_amount: "",
        included_number: "",
        order_amount: "",
        sale_discount: "",
        remark: "",
        link_type: "",
        link_id: "",
        current_name: 1,
        current_row: [],
        card_no_data: [],
    };

    // 表单校验
    rules = {
        customer_id: [
            { required: true, message: "请选择客户", trigger: "blur" },
        ],
        s_id: [{ required: true, message: "请选择商城", trigger: "blur" }],
        product_profiles_id: [
            { required: true, message: "请选择", trigger: "blur" },
        ],
        expiration_date: [
            { required: true, message: "请选择券有效日期", trigger: "blur" },
        ],
        activation_method: [
            { required: true, message: "请选择券激活方式", trigger: "blur" },
        ],
        link_type: [
            { required: true, message: "请选择跳转链接", trigger: "blur" },
        ],
        link_id: [{ validator: this.checkLink, trigger: "blur" }],
        validity_period: [
            { required: true, message: "请选择券有效期", trigger: "blur" },
        ],
        scope_of_application: [
            { required: true, message: "请选择券应用范围", trigger: "blur" },
        ],
        sid: [{ required: true, message: "请选择定制商城", trigger: "blur" }],
        is_electronic_signature: [
            { required: true, message: "请选择券激活方式", trigger: "blur" },
        ],
        sale_unit_price: [
            { required: true, message: "请输入销售单价", trigger: "blur" },
        ],
        sale_number: [
            { required: true, message: "请输入销售数量", trigger: "blur" },
        ],
        included_amount: [
            { required: true, message: "请输入内含金额", trigger: "blur" },
        ],
        included_number: [
            { required: true, message: "请输入内含票数", trigger: "blur" },
        ],
    };
    categoryList: any = [];
    async shopChange(e: any) {
        this.categoryList = await apiGetCategroy({ sid: e });
    }
    linkSelect() {
        this.form.link_id = "";
    }
    oldCardNoData: any = [];
    startChange(e: any, i: any) {
        if (e > this.cardNoData[i]["end_coupon_number"]) {
            this.cardNoData[i]["start_coupon_number"] =
                this.oldCardNoData[i]["start_coupon_number"];
            this.cardNoData[i].card_num = 1;
            this.cardNoDataIndex = new Date().getTime();
        } else {
            if (
                this.cardNoData[i]["start_coupon_number"] &&
                this.cardNoData[i]["end_coupon_number"]
            ) {
                this.cardNoData[i].card_num =
                    this.cardNoData[i]["end_coupon_number"] -
                    this.cardNoData[i]["start_coupon_number"] +
                    1;
            }
            this.oldCardNoData = JSON.parse(JSON.stringify(this.cardNoData));
        }
    }
    endChange(e: any, i: any) {
        if (e < this.cardNoData[i]["start_coupon_number"]) {
            this.cardNoData[i]["end_coupon_number"] =
                this.oldCardNoData[i]["end_coupon_number"];
            this.cardNoData[i].card_num = 1;
            this.cardNoDataIndex = new Date().getTime();
        } else {
            if (
                this.cardNoData[i]["start_coupon_number"] &&
                this.cardNoData[i]["end_coupon_number"]
            ) {
                this.cardNoData[i].card_num =
                    this.cardNoData[i]["end_coupon_number"] -
                    this.cardNoData[i]["start_coupon_number"] +
                    1;
            }
            this.oldCardNoData = JSON.parse(JSON.stringify(this.cardNoData));
        }
    }

    checkLink(rule: any, value: any, callback: any) {
        if (this.form.link_type != 1 && this.form.link_type != 3) {
            if (value == "") return callback(new Error("请选择跳转类目"));
        }
        callback();
    }
    prev() {
        this.steps--;
    }
    next(formName: string) {
        if (this.steps == 2) {
            this.getStockeData();
        }
        if (this.steps == 1) {
            this.getProductFileLists();
        }
        // 验证表单
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            if (this.steps == 2) {
                if (this.form.sale_discount < 0.1) {
                    this.$message.error("销售折扣不得低于0.1");
                    return false;
                }
                if (
                    this.form.coupons_type == 2 ||
                    this.form.coupons_type == 4 ||
                    this.form.coupons_type == 5
                ) {
                    if (this.form.sale_discount > 1) {
                        this.$message.error("销售折扣不得大于1");
                        return false;
                    }
                }
                if (this.form.sale_discount < 0.5) {
                    this.$confirm(
                        "销售折扣率很低，此订单可能会亏损，是否继续？",
                        "提示",
                        {
                            confirmButtonText: "继续",
                            cancelButtonText: "返回继续修改",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.steps++;
                        })
                        .catch(() => {
                            return false;
                        });
                } else {
                    this.steps++;
                }
            } else {
                this.steps++;
            }
            if (!valid) return;
        });
    }

    async getCustomerList() {
        const custom_list_array = await apiGetCustomerList({
            page_type: 0,
            type: 0,
        });
        this.custom_list = custom_list_array.lists;
    }

    async getProductFileLists() {
        const product_file_list_array = await apiProductFileLists({
            page_type: 0,
            product_type: this.form.coupons_type,
            open_status: 1,
        });
        this.productFileLists = product_file_list_array.lists;
    }

    async getShop() {
        this.form.shop_id = "";
        const shopListArray = await apiShopLists({
            app_id: 10,
            expires_status: 1,
            pageType: 2,
        });
        this.shopList = shopListArray.lists;
    }
    selectProductProfile(res: any) {
        const foundItem = this.productFileLists.find((item) => item.id === res);
        if (foundItem) {
            this.form.benchmark_price = foundItem.price_range;
            this.form.discount_cap = foundItem.discount_ceiling;
            this.form.basePrice = foundItem.discount_ceiling;
        }
        this.calculateTotalOrderPrice();
    }

    calculateTotalOrderPrice() {
        if (this.form.sale_unit_price != "" && this.form.sale_number != "") {
            this.form.order_amount = this.amountCalculation(
                this.form.sale_unit_price,
                this.form.sale_number
            );
        }
        if (
            this.form.sale_unit_price != "" &&
            this.form.included_number != "" &&
            this.form.benchmark_price > 0
        ) {
            const price1 = this.amountCalculation_division(
                this.form.sale_unit_price,
                this.form.included_number
            );
            this.form.sale_discount = this.amountCalculation_division(
                price1,
                this.form.benchmark_price
            );
        }
        if (
            (this.form.coupons_type == 2 ||
                this.form.coupons_type == 4 ||
                this.form.coupons_type == 5) &&
            this.form.sale_unit_price != "" &&
            this.form.included_amount != ""
        ) {
            this.form.sale_discount = this.amountCalculation_division(
                this.form.sale_unit_price,
                this.form.included_amount
            );
        }
        console.log(this.form, "this.formthis.form");
        if (
            this.form.coupons_type == 1 &&
            this.form.sale_unit_price != "" &&
            this.form.included_number != "" &&
            this.form.discount_cap != ""
        ) {
            const price1 = this.amountCalculation_division(
                this.form.sale_unit_price,
                this.form.included_number
            );
            this.form.sale_discount = this.amountCalculation_division(
                price1,
                this.form.discount_cap,
                2
            );
        }
    }
    amountCalculation(number1: string, number2: string) {
        // 将输入的金额和乘数转换为数字
        const amount = parseFloat(number1);
        const multiplier = parseFloat(number2);
        // 计算金额乘以乘数，并保留两位小数
        const result = amount * multiplier;
        return result.toFixed(2);
    }
    amountCalculation_division(
        number1: string,
        number2: string,
        fraction: number = 4
    ) {
        // 将输入的金额和乘数转换为数字
        const amount = parseFloat(number1);
        const multiplier = parseFloat(number2);
        // 计算金额乘以乘数，并保留两位小数
        const result = amount / multiplier;
        return result.toFixed(fraction);
    }
    //
    async getStockeData() {
        let data: any = {
            type: 0,
            page_type: 0,
            audit_status: 2,
            real_number: this.form.sale_number,
            coupon_number: this.stockQuery.no1,
            sn: this.stockQuery.no2,
        };
        if (this.form.coupons_type == 1 || this.form.coupons_type == 3) {
            data.type = 1;
        } else {
            data.type = 2;
        }

        let list: any = await apiPhysicalVouchersCouponBackLists(data);
        this.stockeData = list.lists;
    }

    handleSelectionChange(val: any) {
        const refs = this.$refs["stockeData"] as HTMLFormElement;
        if (val.length > 1) {
            refs.clearSelection();
            refs.toggleRowSelection(val.pop());
        }
    }
    selectTable(selection: any, row: any) {
        this.currentRow = [row];
    }

    //选择库存
    handleCurrentChange(row: any) {
        const refs = this.$refs["stockeData"] as HTMLFormElement;
        refs.clearSelection();
        refs.toggleRowSelection(row);
        this.currentRow = [row];
    }

    //新增行
    addCardNo() {
        this.cardNoData.push({
            start_coupon_number: 1000000000,
            end_coupon_number: 1000000001,
            card_num: 0,
        });
        this.cardNoDataIndex = new Date().getTime();
        this.oldCardNoData = JSON.parse(JSON.stringify(this.cardNoData));
    }
    //删除
    delCardNo(index: number) {
        this.cardNoData.splice(index, 1);
    }
    // 点击表单提交
    canSubmit = true;
    async onSubmit(formName: string) {
        if (!this.canSubmit) return;
        this.canSubmit = false;
        let that = this;
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate(async (valid: boolean): Promise<any> => {
            if (!valid) return;
            let canAdd = true;
            if (this.currentName == "1") {
                if (JSON.stringify(this.currentRow) == "{}") {
                    this.$message.error("请选择劵号段");
                    canAdd = false;
                    that.canSubmit = true;
                    return false;
                } else {
                    this.form.current_row = this.currentRow;
                }
            }
            if (this.currentName == "2") {
                if (this.cardNoData.length <= 0) {
                    this.$message.error("请输入劵号段信息");
                    canAdd = false;
                    that.canSubmit = true;
                    return false;
                } else {
                    let count = 0;
                    for (let i in this.cardNoData) {
                        let item = this.cardNoData[i];
                        let temp =
                            item.end_coupon_number - item.start_coupon_number;
                        console.log(item, "11item");
                        if (item.card_num <= 0) {
                            this.$message.error("自定义库存数量不能小于0");
                            canAdd = false;
                            that.canSubmit = true;
                            return false;
                        }
                        count += item.card_num;
                    }

                    if (count != this.form.sale_number) {
                        this.$message.error(
                            "剩余数量不等于销售数量，请重新自定劵号区间"
                        );
                        canAdd = false;
                        that.canSubmit = true;
                        return false;
                    }

                    await apiOrderCheckCardNoData({
                        card_no_data: this.cardNoData,
                        sale_number: this.form.sale_number,
                    }).catch((res: any) => {
                        this.$message.error(res.msg);
                        canAdd = false;
                        that.canSubmit = true;
                        return false;
                    });

                    this.form.card_no_data = this.cardNoData;
                }
            }
            that.form.current_name = that.currentName;
            that.form.validity_period_start = that.form.validity_period[0];
            that.form.validity_period_end = that.form.validity_period[1];
            if (canAdd) {
                await apiOrderAdd(that.form).then(() => {
                    this.$message.success("提交成功!");
                    this.$router.replace("/card/makeCard/materialCard?type=2");
                });
            }

            that.canSubmit = true;
        });
    }
    specialResource: any = {};
    async getSpecialResource() {
        let res = await apiOtherResources({
            page_no: 1,
            page_size: 1000,
            service_fee_status: 0,
        });
        this.specialResource = res.lists;
    }
    created() {
        this.getCustomerList();
        this.getProductFileLists();
        this.getSpecialResource();
        if (this.form.shop_id != "") {
            this.getShop();
        }
    }
}
